import React from 'react';

import classes from "./StudentInfoCard.module.css";
const StudentInfoCard = (props) => {
    return (
        <div className={classes.container}>
            <div className={classes.logo}>
                ASBT
            </div>
            <div className={classes.title}>
               Assessment Round-Grade {props.studentInfo[0].grade} LOA {props.studentInfo[0].loa}
            </div>
            <div className={classes.info}>
                <ul>
                    <li>Student ID: {props.studentInfo[0].studid}</li>
                    <li>Student Name: {`${props.studentInfo[0].first_name} ${props.studentInfo[0].last_name}`}</li>
                    <li>Grade: {props.studentInfo[0].grade}</li>
                    <li>UserName: {props.studentInfo[0].username}</li>
                    <li>Nationality: {props.studentInfo[0].nationality}</li>
                </ul>
            </div>
        </div>
    );
};
export default StudentInfoCard;
