import React from 'react';
import {  Link } from "react-router-dom";

import classes from './NavBar.module.css';
const NavBarSchool= () =>{
    return (
        <nav className={classes.nav}>
            <div className={classes.container}>
                <a href="\" className={classes.Title}>
                    ASBT
                </a>
            </div>
            <div className={classes.container}>
                <ul>
                    <li>
                        <Link to="/school"  className={classes.list}>Logout</Link>
                    </li>
                    <li>
                        <Link to="addStd"  className={classes.list}>Add Student</Link>
                    </li>
                    <li>
                        <Link to="viewStd"  className={classes.list}>View Student</Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to="viewStdProfile"  className={classes.list}>Student Profile</Link>*/}
                    {/*</li>*/}
                </ul>
                <div className={classes.profile}>
                    <div className={classes.name}>
                        School Name A
                    </div>
                    <img src={require('../assets/profile.png')} alt="profile" className={classes.image}/>
                </div>
            </div>
        </nav>
    );
}
export default NavBarSchool;
