import {BrowserRouter, Routes, Route, createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import Home from "../pages/Home";
import Logout from "../pages/Logout"
import NavBar from "../components/NavBar";
import React from "react";
import StudentInfoCard from "../components/StudentInfoCard";
import ExamTypeCard from "../components/ExamTypeCard";
import SelectExamType from "../pages/SelectExamType";
import Login from '../pages/Login';
import ExamPage from "../components/ExamPage";
import SchoolLogin from "../pages/SchoolLogin";
import CourseCard from "../components/CourseCard";
import LevelAssessmentPage from "../pages/LevelAssessmentPage";
import RootLayout from "../pages/RootLayout";
import AddStudent from "../pages/AddStudent";
import ViewStudent from "../pages/ViewStudent";
import SchoolLayout from "../pages/SchoolLayout";
import ViewStudentProfile from "../pages/ViewStudentProfile";

const router = createBrowserRouter([
    {path: '/', element: <Login/>},
    {path: '/isAuth',
        element:<RootLayout/>,
        children:[
            {path: 'loa',element:<LevelAssessmentPage/>},
            {path: 'examtype', element:<SelectExamType/>},
            {path: 'exam', element:<ExamPage/>},
        ]
    },
    {path:'/school', element:<SchoolLogin/>},
    {path: '/school',
        element:<SchoolLayout/>,
        children:[
            {path: 'addStd', element:<AddStudent/>},
            {path: 'viewStd', element:<ViewStudent/>},
            {path: 'viewStd/viewStdProfile', element:<ViewStudentProfile/>}
        ]
    },
    // { path:'/isAuth/examtype',element:<ExamPage/>},
    // Add a catch-all route for 404 Not Found
    { path: '*', element: <Navigate to="/" /> }
]);
function App() {

    return (
        <React.Fragment>
            <RouterProvider router={router}>
            </RouterProvider>
        </React.Fragment>
    );
}

export default App;
