import React from 'react';
import {  Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import classes from './NavBar.module.css';
import {logoutUser} from "../stores/actions/auth";
const NavBar= () =>{
    const dispatch = useDispatch();
    const LogoutHandler = async() => {
        await dispatch(logoutUser());
    }
    const username = useSelector(state => state.auth.username_info);
    return (
        <nav className={classes.nav}>
            <div className={classes.container}>
            <a href="/isAuth/examtype" className={classes.Title}>
                ASBT
            </a>
            </div>
            <div className={classes.container}>
                <ul>
                    <li>
                        <Link to="/isAuth/examtype"  className={classes.list}>Home</Link>
                    </li>
                    <li onClick={LogoutHandler}>
                        <Link to="/"  className={classes.list}>Logout</Link>
                    </li>
                </ul>
            <div className={classes.profile}>
                <div className={classes.name}>
                    {username != null ? username[0].username:'Demo Account A'}
                </div>
                <img src={require('../assets/profile.png')} alt="profile" className={classes.image}/>
            </div>
        </div>
        </nav>
    );
}
export default NavBar;
