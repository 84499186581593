import React, {useState,useRef} from 'react';
import classes from "./LongAnswer.module.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const arabicLayout = {
    'default': [
        'ض ص ث ق ف غ ع ه خ ح ج د',
        'ش س ي ب ل ا ت ن م ك ط',
        '{shift} ئ ء ؤ ر ل آ {enter}',
        '{space} ز ظ ـ ٩ ٨ ٧ ٦ ٥ ٤ ٣ ٢ ١'
    ],
    'shift': [
        'َ ً ُ ٌ إ ’ـ ؛ < > ( )',
        'َس ١١٠ ١١١ ١١٢ ١١٣ ١١٤ ١١٥ ١١٦ ١١٧ ١١٨ ١١٩ ١٢٠',
        '{shift} ء ١ ١ ١ ١ ١ ١ ١ ١ ١ ١ {enter}',
        '{space} ة ـ ٩ ٨ ٧ ٦ ٥ ٤ ٣ ٢ ١'
    ]
};

const LongAnswer = (props) => {
    const [layoutName, setLayoutName] = useState("default");
    const [input, setInput] = useState("");

    const onChange = (newInput) => {
        setInput(newInput);
        console.log("Input changed", newInput);
        props.add(props.id, newInput);
    };

    const onKeyPress = (button) => {
        console.log("Button pressed", button);

        // If you want to handle the shift and caps lock buttons
        if (button === "{shift}" || button === "{lock}") handleShift();
    };

    const handleShift = () => {
        setLayoutName((prevLayoutName) =>
            prevLayoutName === "default" ? "shift" : "default"
        );
    };

    const onChangeInput = (event) => {
        const newInput = event.target.value;
        setInput(newInput);
    };
    return(
        <React.Fragment>
            <div className={classes.outerContainer}>
                <div className={classes.image}>
                    {props.image && <img src={props.image} alt={props.id} className={classes.url}/>}
                    {props.audio && <audio controls src={props.audio} className={classes.audio}/>}
                </div>
                <div className={classes.text}>
                    <p className={classes.question}>{props.question}</p>
                    <div className={classes.innerContainer}>
                        <textarea id={props.question} name={props.question} rows={10} cols={40} value={input} />
                    </div>
                    <Keyboard
                        layoutName={layoutName}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        layout={arabicLayout}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default LongAnswer;
