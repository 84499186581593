import React, {useEffect} from 'react';
import {Outlet, useNavigate} from "react-router-dom";
import NavBar from "../components/NavBar";
import {useSelector} from "react-redux";

const RootLayout = () => {
    const isLoggedIn = useSelector(state=>state.auth.isLoggedIn);
    const navigate = useNavigate();
    useEffect(()=>{
        console.log(isLoggedIn);
        if(!isLoggedIn){
            navigate('/',{replace: true});
        }
    },[isLoggedIn])
    return (
        <React.Fragment>
            <NavBar/>
            <Outlet/>
        </React.Fragment>
    )
};

export default RootLayout;
