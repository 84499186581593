import React, {useEffect, useState} from 'react';

import {DndContext,closestCenter} from "@dnd-kit/core";
import {arrayMove,SortableContext,horizontalListSortingStrategy} from "@dnd-kit/sortable";
import classes from './Jumble.module.css';
import OptionContainer from "../OptionContainer";
import ImageContainer from "../imageContainer";

const Jumble = (props) => {
    const [arrangement, setArrangement] = useState([...props.options])
    const [images, setImages] = useState([...props.images]);
    useEffect(()=>{
        // console.log(arrangement);
        if(props.options.length > 0){
            const answer = arrangement.join(' ');
            props.add(props.id, answer);
        }
        // if(props.images.length > 0){
        //     const answer = images.join(",,");
        //     props.add(props.id,answer);
        // }
    // },[arrangement,images])
},[arrangement])

    const handleDragEnd = async(event) =>{
        // console.log("Drag End called");
        const {active, over} = event;
        console.log(event);
        // console.log("ACTIVE: " + active.id);
        // console.log('OVER: '+ over.id);

        if(active.id !== over.id){
           await setArrangement((items) => {
                const activeIndex = items.indexOf(active.id);
                const overIndex = items.indexOf(over.id);
                console.log(items, activeIndex, overIndex)
                // const array = arrayMove(items, activeIndex, over);
                return arrayMove(items, activeIndex, overIndex);
            })
        }

    }
    // const handleDragEnd2 = async(event) =>{
    //     // console.log("Drag End called");
    //     const {active, over} = event;
    //     console.log(event);
    //     // console.log("ACTIVE: " + active.id);
    //     // console.log('OVER: '+ over.id);
    //
    //     if(active.id !== over.id){
    //         await setImages((items) => {
    //             const activeIndex = items.indexOf(active.id);
    //             const overIndex = items.indexOf(over.id);
    //             console.log(items, activeIndex, overIndex)
    //             // const array = arrayMove(items, activeIndex, over);
    //             return arrayMove(items, activeIndex, overIndex);
    //         })
    //     }
    //
    // }

    return (
        <React.Fragment>
            <div className={classes.outerContainer}>
                {/*<DndContext*/}
                {/*    collisionDetection={closestCenter}*/}
                {/*    onDragEnd={handleDragEnd2}>*/}
                        <p className={classes.question}> {props.data.question}</p>
                {/*        <SortableContext items={images} strategy={horizontalListSortingStrategy}>*/}
                            <div className={classes.optionContainer}>
                                {images.map(item => <ImageContainer src={item} alt={item} key={item} id={item} className={classes.image}/>)}
                            </div>
                {/*        </SortableContext>*/}
                {/*</DndContext>*/}
                <DndContext
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}>
                        <SortableContext items={arrangement} strategy={horizontalListSortingStrategy}>
                        <div className={classes.optionContainer}>
                            {arrangement.map(item => <OptionContainer option={item} id={item} key={item}/>)}
                        </div>
                        </SortableContext>
                    </DndContext>
        </div>
        </React.Fragment>
    );
};

export default Jumble;
