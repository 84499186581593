import React,{ useState,useEffect } from 'react';
import classes from './ExamPage.module.css';
import Bullet from "../../src/ui/Bullet";
import { useTimer } from 'react-timer-hook';
import {useSelector,useDispatch} from "react-redux";
import Mcq from "./questions/Mcq";
import TrueOrFalse from "./questions/TrueOrFalse";
import LongAnswer from "./questions/LongAnswer";
import ShortAnswer from "./questions/ShortAnswer";
import SpeakingSkills from "./questions/SpeakingSkills";
import Jumble from "./questions/Jumble";
import {submit, timerExpiredTrue, timerExpiredFalse, submitResponse} from "../stores/actions/auth";
import {useNavigate} from "react-router-dom";
import Unseen from "./questions/Unseen";
import Listening from "./questions/Listening";

export const MyTImer =({ expiryTimestamp }) => {
    const dispatch = useDispatch();
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({ expiryTimestamp, onExpire: async () => {
            await dispatch(timerExpiredTrue());
        } });

    useEffect(()=>{
        start();
    },[])

    return(
        <React.Fragment>
            {`${minutes}:${seconds}`}
        </React.Fragment>
    );
}
const ExamPage = () => {

    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         // Display a confirmation prompt
    //         const message = 'Are you sure you want to leave? Your changes may not be saved.';
    //         event.returnValue = message; // Standard for most browsers
    //         return message; // For some older browsers
    //     };
    //
    //     // Attach the event listener when the component mounts
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //
    //     // Detach the event listener when the component unmounts
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, );

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const timerExpired = useSelector(state => state.auth.timerExpire);
    const examType = useSelector(state => state.auth.exam);
    const title = useSelector(state=>state.auth.exam);
    const readingMcq = useSelector(state=>state.auth.readingMcq);
    const readingTrueOrFalse = useSelector(state=>state.auth.readingTrueOrFalse);
    const readingLongAnswer = useSelector(state=>state.auth.readingLongAnswer);
    const readingShortAnswer = useSelector(state=>state.auth.readingShortAnswer);
    const readingJumble = useSelector(state=>state.auth.readingJumble);
    const readingUnseen = useSelector(state=>state.auth.readingUnseen);
    const readingFillBlanks = useSelector(state => state.auth.readingFillBlanks);
    // useEffect(()=>{
    //     console.log(readingUnseen);
    // },[readingUnseen])

    const writingData = useSelector(state=>state.auth.writingData);

    const listeningMcq = useSelector(state=>state.auth.listeningMcq);
    const listeningTrueOrFalse = useSelector(state=>state.auth.listeningTrueOrFalse);
    const listeningLongAnswer = useSelector(state=>state.auth.listeningLongAnswer);
    const listeningShortAnswer = useSelector(state=>state.auth.listeningShortAnswer);
    const listeningFillBlanks = useSelector(state => state.auth.listeningFillBlanks);
    const speakingData = useSelector(state=>state.auth.speakingData);
    const listening = useSelector(state => state.auth.listening);


    const time = new Date();
    time.setSeconds(time.getSeconds() + 2400); // 40 minutes timer

    const [mcq, setMcq] = useState([]);
    const [torf, setTorf] = useState([]);
    const [long, setLong] = useState([]);
    const [short, setShort] = useState([]);
    const [jumble, setJumble] = useState([]);
    const [speaking, setSpeaking] = useState([]);
    const [fill, setFill] = useState([]);
    const [unseen, setUnseen] =useState([]);
    const [unseenMcq, setUnseenMcq] = useState([]);
    const [unseenTorf, setUnseenTorf] = useState([]);
    const [unseenLong, setUnseenLong] = useState([]);
    const [unseenShort, setUnseenShort] = useState([]);
    const [unseenJumble, setUnseenJumble] = useState([]);
    const [unseenFill, setUnseenFill] = useState([]);
    const addAnswerMcq = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = mcq.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setMcq([...mcq.slice(0,array),...mcq.slice(array+1, mcq.length)]);
            setMcq(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setMcq(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerListeningMcq = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = unseenMcq.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setMcq([...mcq.slice(0,array),...mcq.slice(array+1, mcq.length)]);
            setMcq(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setMcq(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerUnseenMcq = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = unseenMcq.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setUnseenMcq([...unseenMcq.slice(0,array),...unseenMcq.slice(array+1, unseenMcq.length)]);
            setUnseenMcq(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setUnseenMcq(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerFill = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = fill.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setFill([...fill.slice(0,array),...fill.slice(array+1, fill.length)]);
            setFill(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setFill(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerUnseenFill = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = unseenFill.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setUnseenFill([...unseenFill.slice(0,array),...unseenFill.slice(array+1, unseenFill.length)]);
            setUnseenFill(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setUnseenFill(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerTorf = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = torf.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setTorf([...torf.slice(0,array),...torf.slice(array+1, torf.length)]);
            setTorf(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setTorf(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerUnseenTorf = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = unseenTorf.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setUnseenTorf([...unseenTorf.slice(0,array),...unseenTorf.slice(array+1, unseenTorf.length)]);
            setUnseenTorf(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setUnseenTorf(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerLong = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = long.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setLong([...long.slice(0,array),...long.slice(array+1, long.length)]);
            setLong(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setLong(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerUnseenLong = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = unseenLong.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setUnseenLong([...unseenLong.slice(0,array),...unseenLong.slice(array+1, unseenLong.length)]);
            setUnseenLong(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setUnseenLong(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerShort = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = short.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setShort([...short.slice(0,array),...short.slice(array+1, short.length)]);
            setShort(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setShort(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerUnseenShort = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = unseenShort.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setUnseenShort([...unseenShort.slice(0,array),...unseenShort.slice(array+1, unseenShort.length)]);
            setUnseenShort(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setUnseenShort(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerJumble = (id, data) => {
        // const answer = data.join(' ');
        const replacement = { id: id,answer: data };
        let array = jumble.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            // setJumble([...jumble.slice(0,array),...jumble.slice(array+1, jumble.length)]);
            // setJumble(prevState => [...prevState,{id: id, answer: data}])
            const newArray = jumble.filter(item => item.id !== replacement.id);
            setJumble(prevState => [...newArray,replacement]);
        } else {
            setJumble(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerUnseenJumble = (id, data) => {
        // const answer = data.join(' ');
        const replacement = { id: id,answer: data };
        let array = unseenJumble.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            // setJumble([...jumble.slice(0,array),...jumble.slice(array+1, jumble.length)]);
            // setJumble(prevState => [...prevState,{id: id, answer: data}])
            const newArray = unseenJumble.filter(item => item.id !== replacement.id);
            setUnseenJumble(prevState => [...newArray,replacement]);
        } else {
            setUnseenJumble(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const addAnswerSpeaking = (id, data) => {
        const replacement = { id: id,answer: data };
        let array = speaking.findIndex((x)=> x.id === replacement.id)
        if(array >= 0){
            setSpeaking([...speaking.slice(0,array),...speaking.slice(array+1, speaking.length)]);
            setSpeaking(prevState => [...prevState,{id: id, answer: data}])
        } else {
            setSpeaking(prevState => [...prevState,{id: id, answer: data}])
        }
    }
    const navigationHandler = () => {
        navigate('../examtype',{replace: true});
    }
    const submitHandler = async (event) => {
        event.preventDefault();
        let prefix = null;
        switch(examType){
            case 'Reading':
                prefix = 'read';
                break;
            case 'Writing':
                prefix = 'write';
                break;
            case 'Listening':
                prefix = 'listen';
                break;
            case 'Speaking':
                prefix = 'speak'
                break;
            case 'Unseen Comprehension':
                prefix = 'unseenlisten';
                break;
        }
        // const answer = {
        //     [`${prefix}mcqs`]: [[{id: 'name', relationTable:`${prefix}mcqs`}],[...mcq]],
        //     [`${prefix}truefalses`]: [[{id: 'name',relationTable:`${prefix}truefalses`}],[...torf]],
        //     [`${prefix}longques`]: [[{id: 'name',relationTable:`${prefix}longques`}],[...long]],
        //     [`${prefix}shortques`]: [[{id: 'name', relationTable: `${prefix}shortques`}],[...short]],
        //     [`${prefix}jumbles`]: [[{id:'name',relationTable:`${prefix}jumbles`}],[...jumble]],
        //     [`${prefix}writings`]: [[{id:'name', relationTable:`${prefix}writings`}],[...long]],
        //     ['speakings']:[[{id:'name', relationTable:'speakings'}],[...speaking]]
        // };
        const answer = prefix === 'write'|| prefix === 'speak' ? [
                [{relationTable:`writings`},[...long]],
                [{relationTable:'speakings'},[...speaking]]]  : [
            [{relationTable:`${prefix}mcqs`},[...mcq]],
            [{relationTable:`${prefix}truefalses`},[...torf]],
            [{relationTable:`${prefix}longques`},[...long]],
            [{relationTable: `${prefix}shortques`},[...short]],
            [{relationTable:`${prefix}jumbles`},[...jumble]],
            [{relationTable:`${prefix}fillblanks`},[...fill]],
            [{relationTable:`unseen${prefix}mcqs`},[...unseenMcq]],
            [{relationTable:`unseen${prefix}truefalses`},[...unseenTorf]],
            [{relationTable:`unseen${prefix}longques`},[...unseenLong]],
            [{relationTable: `unseen${prefix}shortques`},[...unseenShort]],
            [{relationTable:`unseen${prefix}jumbles`},[...unseenJumble]],
            [{relationTable:`unseen${prefix}fillblanks`},[...unseenFill]],
        ]

        await dispatch(submit(answer));
        navigationHandler();
    }
    const expiredTimerHandler = async() => {
        await dispatch(timerExpiredFalse());
        let prefixTimer = null;
        switch(examType){
            case 'Reading':
                prefixTimer = 'read';
                break;
            case 'Writing':
                prefixTimer = 'write';
                break;
            case 'Listening':
                prefixTimer = 'listen';
                break;
            case 'Speaking':
                prefixTimer = 'speak'
                break;
            case 'Unseen Comprehension':
                prefixTimer = 'unseenlisten';
                break;
        }
        // const answer = {
        //     exam: prefix,
        //     mcqs:[...mcq],
        //     truefalses: [...torf],
        //     longques: [...long],
        //     shortques: [...short],
        //     jumbles: [...jumble],
        //     speakings: [...speaking],
        //     writings: [...long],
        // }
        const answer = prefixTimer === 'write'|| prefixTimer === 'speak' ? [
            [{relationTable:`writings`},[...long]],
            [{relationTable:'speakings'},[...speaking]]
        ] :  [
            [{relationTable:`${prefixTimer}mcqs`},[...mcq]],
                [{relationTable:`${prefixTimer}truefalses`},[...torf]],
                [{relationTable:`${prefixTimer}longques`},[...long]],
                [{relationTable: `${prefixTimer}shortques`},[...short]],
                [{relationTable:`${prefixTimer}jumbles`},[...jumble]],
            [{relationTable:`${prefixTimer}fillblanks`},[...fill]],
            [{relationTable:`unseen${prefixTimer}mcqs`},[...unseenMcq]],
            [{relationTable:`unseen${prefixTimer}truefalses`},[...unseenTorf]],
            [{relationTable:`unseen${prefixTimer}longques`},[...unseenLong]],
            [{relationTable: `unseen${prefixTimer}shortques`},[...unseenShort]],
            [{relationTable:`unseen${prefixTimer}jumbles`},[...unseenJumble]],
            [{relationTable:`unseen${prefixTimer}fillblanks`},[...unseenFill]],
            ];
        console.log('Answer submitteed')
        await dispatch(submit(answer));
        navigationHandler();
    }
    // useEffect(()=>{
    //     console.log("speaking answers==>", speaking);
    // },[speaking]);
    if(timerExpired){
        expiredTimerHandler();
    }
    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.inline}>
                    <div className={classes.title}>
                        {title}
                    </div>
                    <div className={classes.timer}>
                        <MyTImer expiryTimestamp={time}/>
                    </div>
                </div>
            </div>
            <div className={classes.infoContainer}>
                {title === "Writing" && <div className={classes.info}>
                        <Bullet>There are Five Questions.</Bullet><br/>
                        <Bullet>You will need to Answer only three Questions.</Bullet><br/>
                        <Bullet>The assessment out of 25 Marks.</Bullet><br/>
                        <Bullet>There are two options to answer: using the <span>Keyboard</span> or <span>by hand writing</span>.</Bullet>
                    </div>}
                {title === "Listening" && <div className={classes.info}>
                    <Bullet>Total Questions are 15, Divided into Two Listening parts.</Bullet><br/>
                    <Bullet>You must answer all questions.</Bullet><br/>
                    <Bullet>The assessment out of 25 marks.</Bullet>
                </div> }
                {title === "Reading" && <div className={classes.info}>
                    <Bullet>Total Questions are 15, Divided into Two Reading comprehension.</Bullet><br/>
                    <Bullet>You must answer all questions.</Bullet><br/>
                    <Bullet>The assessment out of 25 marks.</Bullet>
                </div> }
                {title === "Speaking" && <div className={classes.info}>
                    <Bullet>There are Five Questions in the speaking assessment.</Bullet><br/>
                    <Bullet>You must answer all the questions.</Bullet><br/>
                    <Bullet>The assessment Out of 25 marks.</Bullet><br/>
                    <Bullet>You must give the permission to <span>the microphone</span>.</Bullet>
                </div> }
                {title === "Unseen Comprehension" && <div className={classes.info}>
                    <Bullet>Total Questions are 15, Divided into Two Unseen comprehension.</Bullet><br/>
                    <Bullet>You must answer all questions.</Bullet><br/>
                    <Bullet>The assessment out of 25 marks.</Bullet>
                </div> }
            </div>
            {/*Reading Questions render here*/}
            <div className={classes.questions}>
                {title === "Reading" && <form onSubmit={submitHandler}>
                    {readingMcq.map(item => <Mcq question={item.readmcqs} image={item.readmcqs.url} id={item.readmcqs.reading_id} key={item.id} add={addAnswerMcq}/>)}
                    {readingFillBlanks.map(item => <Mcq question={item.readfillblanks} image={item.readfillblanks.url} id={item.readfillblanks.reading_id} key={item.id} add={addAnswerFill}/>)}
                    {readingTrueOrFalse.map(item => <TrueOrFalse question={item.readtruefalses} image={item.readtruefalses.url} id={item.readtruefalses.reading_id} key={item.id} add={addAnswerTorf}/>)}
                    {readingLongAnswer.map(item => <LongAnswer question={item.readlongques.question} image={item.readlongques.url} id={item.readlongques.reading_id} key={item.id} add={addAnswerLong}/>)}
                    {readingShortAnswer.map(item => <ShortAnswer question={item.readshortques} image={item.readshortques.url} id={item.readshortques.reading_id} key={item.id} add={addAnswerShort}/>)}
                    {readingJumble.map(item => <Jumble data={item.readjumbles} id={item.readjumbles.reading_id} options={item.readjumbles.options.filter(item => item !== '')} images={item.readjumbles.images.filter(item => item !== '')} add={addAnswerJumble}/>)}
                    {readingUnseen && <Unseen key={readingUnseen.id} data={readingUnseen} addMcq={addAnswerUnseenMcq} addJumble={addAnswerUnseenJumble} addFill={addAnswerUnseenFill} addLong={addAnswerUnseenLong} addShort={addAnswerUnseenShort} addTof={addAnswerUnseenTorf}/>}
                    {(readingMcq.length>0 || readingShortAnswer.length>0 || readingLongAnswer.length>0 || readingTrueOrFalse.length>0 || readingJumble.length>0 || readingFillBlanks.length >0) && <div className={classes.submit}>

                        <input type="submit" />
                    </div>}
                </form>}
                {title === "Writing" && <form onSubmit={submitHandler}>
                    {writingData.map(item => <LongAnswer question={item.question} id={item.id} image={item.url} key={item.id} add={addAnswerLong}/>)}
                    {(writingData.length>0) && <div className={classes.submit}>
                        <input type="submit" />
                    </div>}
                </form>}
                {title === "Listening" && <form onSubmit={submitHandler}>
                    {/*{listeningMcq.map(item => <Mcq question={item.listenmcqs} audio={item.listenmcqs.url} id={item.listenmcqs.listening_id} key={item.id} add={addAnswerMcq}/>)}*/}
                    {/*{listeningFillBlanks.map(item => <Mcq question={item.listenfillblanks} audio={item.listenfillblanks.url} id={item.listenfillblanks.listening_id} key={item.id} add={addAnswerFill}/>)}*/}
                    {/*{listeningTrueOrFalse.map(item => <TrueOrFalse question={item.listentruefalses} audio={item.listentruefalses.url} id={item.listentruefalses.listening_id} key={item.id} add={addAnswerTorf}/>)}*/}
                    {/*{listeningLongAnswer.map(item => <LongAnswer question={item.listenlongques.question} audio={item.listenlongques.url} id={item.listenlongques.listening_id} key={item.id} add={addAnswerLong}/>)}*/}
                    {/*{listeningShortAnswer.map(item => <ShortAnswer question={item.listenshortques} audio={item.listenshortques.url} id={item.listenshortques.listening_id} key={item.id} add={addAnswerShort}/>)}*/}
                    {listening && <Listening key={listening.audio[0].id} data={listening} addMcq={addAnswerMcq} addFill={addAnswerFill} addLong={addAnswerLong} addShort={addAnswerShort} addTof={addAnswerTorf}/>}
                    {(listening.audioQuestions.length > 0) && <div className={classes.submit}>
                        <input type="submit" />
                    </div>}
                </form>}
                {title === 'Speaking' && <form onSubmit={submitHandler}>
                    {speakingData.map(item=><SpeakingSkills question={item.question} id={item.id} image={item.url} key={item.id} add={addAnswerSpeaking}/>)}
                    {(speakingData.length>0) && <div className={classes.submit}>
                        <input type="submit" />
                    </div>}
                </form>}
                {/*{title === "Unseen Comprehension" && <form onSubmit={submitHandler}>*/}
                {/*    {unseenMcq.map(item => <Mcq question={item.unseenlistenmcqs} image={item.unseenlistenmcqs.url} id={item.unseenlistenmcqs.id} key={item.id} add={addAnswerMcq}/>)}*/}
                {/*    {unseenTrueOrFalse.map(item => <TrueOrFalse question={item.unseenlistentruefalses} image={item.unseenlistentruefalses.url} id={item.unseenlistentruefalses.listening_id} key={item.id} add={addAnswerTorf}/>)}*/}
                {/*    {unseenLongAnswer.map(item => <LongAnswer question={item.unseenlistenlongques.question} audio={item.unseenlistenlongques.url} id={item.unseenlistenlongques.listening_id} key={item.id} add={addAnswerLong}/>)}*/}
                {/*    {unseenShortAnswer.map(item => <ShortAnswer question={item.unseenlistenshortques} image={item.unseenlistenshortques.url} id={item.unseenlistenshortques.listening_id} key={item.id} add={addAnswerShort}/>)}*/}
                {/*    {(unseenMcq.length>0 || unseenTrueOrFalse.length>0 || unseenLongAnswer.length>0 || unseenShortAnswer.length>0) && <div className={classes.submit}>*/}
                {/*        <input type="submit" />*/}
                {/*    </div>*/}
                {/*    }*/}
                {/*</form>}*/}
            </div>
        </React.Fragment>
    );
}

export default ExamPage;
