import React, {useState} from 'react';
import classes from "./CustomModal.module.css";
import { Modal } from "react-modal-overlay";
const CustomModal = (props) => {
    return(
        <React.Fragment>
            <Modal show={props.open}>
                <div className={classes.background}>
            <div className={classes.outerContainer}>
                <div className={classes.innerContainer}>
                    {props.children}
                </div>
                <input type="button" value="Close" className={classes.button} onClick={props.onClose}/>
            </div>
                </div>
            </Modal>
        </React.Fragment>
    );
}
export default CustomModal;
