import React, {useState} from 'react';
import classes from "./TrueOrFalse.module.css";

const TrueOrFalse = (props) => {
    const [state, setState] = useState(null);
    const radioChangeHandler = (e) => {
        setState(e.target.value);
        props.add(props.id,e.target.value);
    };
    return(
        <React.Fragment>
            <div className={classes.outerContainer}>
                <div className={classes.img}>
                    {props.image && <img src={props.image} alt={props.id} className={classes.image}/>}
                    {props.audio && <audio controls src={props.audio}/>}
                </div>
                <p className={classes.question}>{props.question.question}</p>
                <div className={classes.innerContainer}>
                    <div className={classes.group}>
                        <input type="radio" value="True" name={props.question.question} onChange={radioChangeHandler}/> <label htmlFor="True">True</label>
                    </div>
                    <div className={classes.group}>
                        <input type="radio" value="False" name={props.question.question} onChange={radioChangeHandler}/> <label htmlFor="False">False</label>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default TrueOrFalse;
