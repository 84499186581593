import classes from "./ViewStudent.module.css";
import {useNavigate} from "react-router-dom";

function ViewStudent() {
    const navigate = useNavigate();
    const navigationHandler = () => {
        navigate('viewStdProfile');
    };
    const studentListHeading = () => {
        return (
            <>
                <div className={classes.studentContainerH}>
                    <div className={classes.srNoH}>Sr No.</div>
                    <div className={classes.stdNameH}>Student Name</div>
                    <div className={classes.regNumbH}>Registration Number</div>
                    <div className={classes.rollNumH}>Roll No.</div>
                    <div className={classes.buttonH}>Action</div>
                </div>
            </>
        );
    }
    const studentList = (no,studentname, regnum, rollnum, view) => {
        return (
            <>
                <div className={classes.studentContainer}>
                    <div className={classes.srNo}>{no}</div>
                    <div className={classes.stdName}>{studentname}</div>
                    <div className={classes.regNumb}>{regnum}</div>
                    <div className={classes.rollNum}>{rollnum}</div>
                    <div className={classes.button} onClick={navigationHandler}>{view}</div>
                </div>
            </>
        );
    }

    return (
        <div className={classes.outerContainer}>
            <div className={classes.innerContainer}>
                <div className={classes.headingContainer}>
                    <img className={classes.image} src={require('../assets/student.png')} />
                    <div className={classes.headingText}>Students</div>
                </div>
                {studentListHeading()}
                {studentList("1.","Siddhesh Siddhesh", "2020ACSC1111029","72", "View")}
                {studentList("2.","Rujul Rujul", "2020ACSC1121035","59", "View")}
                {studentList("3.","Nikhil Nikhil", "2020ACSC5058051","52", "View")}
                {studentList("4.","Amol Amol", "2020ACSC2530001","02", "View")}
                {studentList("5.","Ruzul Ruzul", "2020ACSC1651009","60", "View")}
            </div>
        </div>
    );
}

export default ViewStudent;
