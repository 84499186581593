// import ReduxThunk from 'redux-thunk';
// import {createStore, combineReducers, applyMiddleware} from 'redux';
// import { Provider } from 'react-redux';
// import WebNavigation from "./navigation/WebNavigation";
// import authReducers from './stores/reducers/auth';
// import AddStudent from "./pages/AddStudent";
// const rootReducer = combineReducers({
//     auth: authReducers,
// });
//
// const store = createStore(rootReducer, applyMiddleware(ReduxThunk));
// export default function App() {
//
//     return (
//         <Provider store={store}>
//             <WebNavigation/>
//             {/*<AddStudent/>*/}
//         </Provider>
//     );
// }


import React, {useEffect, useState} from 'react';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // You can choose your storage engine

import WebNavigation from './navigation/WebNavigation';
import authReducers from './stores/reducers/auth';

const rootReducer = combineReducers({
    auth: authReducers,
});

const persistConfig = {
    key: 'root',
    storage, // Use the appropriate storage engine (e.g., localStorage, sessionStorage)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, applyMiddleware(thunk));
const persistor = persistStore(store);

function App() {
    const [cancelUnload, setCancelUnload] = useState(false);
    useEffect(() => {
        document.title = 'ASBT';
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Display a confirmation prompt
            const message = 'Are you sure you want to leave? Your changes may not be saved.';
            event.returnValue = message; // Standard for most browsers
            return message; // For some older browsers
        };

        // Attach the event listener when the component mounts
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, );



    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <WebNavigation />
                {/* <AddStudent /> */}
            </PersistGate>
        </Provider>
    );
}

export default App;
