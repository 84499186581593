import React from "react";
import classes from "./ViewStudentProfile.module.css";
import ArabicInitialsSeparater from "../components/ArabicInitialsSeparater";

function ViewStudentProfile() {
    const renderFieldsContainer = (title, value) => {
        return (
            <>
                <div className={classes.fieldContainer}>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.value}>{value}</div>
                </div>
            </>
        );
    }

    return (
        <div className={classes.outerContainer}>
            <div className={classes.innerContainer}>
                <div className={classes.heading}><div className={classes.headingText}>Profile</div></div>
                <div className={classes.profile}>
                    <div className={classes.profilePic}>
                        <img className={classes.profilePhoto} src={require('../assets/profile.png')}/>
                    </div>
                    <div className={classes.infoContainer}>
                        {renderFieldsContainer("School Name:", "ABCDEFGHIJKLMN")}
                        {renderFieldsContainer("Reg. Number:", "SCHOOL0021356")}
                        {renderFieldsContainer("Student Name:", "Joe Bidden")}
                        {renderFieldsContainer("Nationality:", "Arabian")}
                        {renderFieldsContainer("Grade/Year:", "1/2")}
                        {renderFieldsContainer("DOB:", "02/03/2002")}
                        {renderFieldsContainer("Gender:", "Male")}
                        {renderFieldsContainer("Years Studied:", "A1(1/2)")}
                        {renderFieldsContainer("Level Of Assessment:", "3")}
                        {renderFieldsContainer("General/SEN:", "General")}
                    </div>
                </div>
                <div className={classes.buttonContainer}>
                    <div className={classes.button}>
                        <div className={classes.buttonText}>Edit Profile</div>
                    </div>
                    <div className={classes.button}>
                        <div className={classes.buttonText}>Delete Profile</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewStudentProfile;
