import React from 'react';
import Mcq from "./Mcq";
import Jumble from "./Jumble";
import TrueOrFalse from "./TrueOrFalse";
import LongAnswer from "./LongAnswer";
import ShortAnswer from "./ShortAnswer";
import classes from './Unseen.module.css';
const Unseen = (props) => {
    return(
        <React.Fragment>
            {/*Passage div*/}
            <div className={classes.outerContainer}>
                <div className={classes.title}>
                    <b>Unseen Passage</b>
                </div>
                {props.data.passage[0].passage}
            </div>

            {/*Questions div*/}
            <div>
                {props.data.passageQuestions.map(item => {
                    // console.log(item.relationTable);
                    switch (item.relationTable){
                        case 'Unseenreadmcqs':
                            return <Mcq
                                question={item[0]}
                                image={item[0].url}
                                id={item[0].id }
                                key={item[0].id }
                                add={props.addMcq}
                                option={item.options}
                            />
                        case 'Unseenreadjumbles':
                            return <Jumble
                                data={item[0]}
                                id={item[0].id}
                                key={item[0].id}
                                options={item.options}
                                images={item.images}
                                add={props.addJumble}
                            />
                        case 'Unseenreadfillblanks':
                            return <Mcq
                                question={item[0]}
                                image={item[0].url}
                                id={item[0].id }
                                key={item[0].id }
                                add={props.addFill}
                                option={item.options}
                            />
                        case 'Unseenreadlongques':
                            return <LongAnswer
                                question={item[0].question}
                                image={item[0].url}
                                id={item[0].id}
                                key={item[0].id}
                                add={props.addLong}
                            />
                        case 'Unseenreadshortques':
                            return <ShortAnswer
                                question={item[0]}
                                image={item[0].url}
                                id={item[0].id}
                                key={item[0].id}
                                add={props.addShort}
                            />
                        case 'Unseenreadtruefalses':
                            return <TrueOrFalse
                                question={item[0]}
                                image={item[0].url}
                                id={item[0].id}
                                key={item[0].id}
                                add={props.addTof}
                            />
                    }
                })}
            </div>
        </React.Fragment>
    );
};

export default Unseen;
