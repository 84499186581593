import {
    SET_DID_TRY_AUTO_LOGIN,
    AUTHENTICATE,
    LOGOUT,
    LOA_TYPE,
    EXAM_TYPE,
    READING_MCQ,
    READING_TRUEORFALSE,
    READING_SHORT_ANSWER,
    READING_LONG_ANSWER,
    READING_JUMBLE,
    READING_FILLBLANKS,
    WRITING_DATA,
    LISTENING_MCQ,
    LISTENING_TRUEORFALSE,
    LISTENING_SHORT_ANSWER,
    LISTENING_LONG_ANSWER,
    LISTENING_FILLBLANKS,
    LISTENING,
    SUBMIT,
    TIMER,
    USERNAME_INFO,
    UNSEEN_LONG_ANSWER,
    UNSEEN_SHORT_ANSWER,
    UNSEEN_MCQ,
    UNSEEN_TRUEORFALSE,
    SPEAKING_DATA, READING_UNSEEN, // this we are going to try first
} from "../actions/auth";

const initialState = {
    unseenMcq:null,
    unseenLongAnswer:null,
    unseenShortAnswer:null,
    unseenTrueOrFalse:null,
    token: null, // we will save token here.
    isLoggedIn: false,
    loa: null,
    exam: null,
    readingMcq: null,
    readingTrueOrFalse: null,
    readingLongAnswer: null,
    readingShortAnswer: null,
    readingJumble: null,
    readingUnseen: null,
    readingFillBlanks: null,
    writingData: null,
    listeningData: null,
    listeningMcq: null,
    listeningTrueOrFalse: null,
    listeningLongAnswer: null,
    listeningShortAnswer: null,
    listeningFillBlanks: null,
    speakingData: null,
    submit: null,
    username_info:null,
    timerExpire: false,
    listening: null,
};

export default (state = initialState, action) => {
    switch (action.type) {

        case SUBMIT:
            return {
                ...state,
                submit: action.payload,
            }
        case LISTENING:
            return {
                ...state,
                listening: action.payload,
            }
        case READING_UNSEEN:
            return {
                ...state,
                readingUnseen:action.payload,
            }
        case READING_FILLBLANKS:
            return {
                ...state,
                readingFillBlanks: action.payload,
            }
        case UNSEEN_MCQ:
            return {
                ...state,
                unseenMcq: action.payload,
            }
        case UNSEEN_LONG_ANSWER:
            return {
                ...state,
                unseenLongAnswer: action.payload,
            }
        case UNSEEN_SHORT_ANSWER:
            return {
                ...state,
                unseenShortAnswer: action.payload,
            }
        case UNSEEN_TRUEORFALSE:
            return {
                ...state,
                unseenTrueOrFalse: action.payload,
            }
        case USERNAME_INFO:
            return{
                ...state,
                username_info: action.payload,
            }
        case TIMER:
            return {
                ...state,
                timerExpire: action.payload,
            }
        case AUTHENTICATE:
            return {
                ...state,
                token: action.payload,
            };

        case LOA_TYPE:
            return {
                ...state,
                loa: action.payload,
            }

        case EXAM_TYPE:
            return {
                ...state,
                exam: action.payload,
            }

        case READING_MCQ:
            return {
                ...state,
                readingMcq: action.payload,
            }

        case READING_TRUEORFALSE:
            return {
                ...state,
                readingTrueOrFalse: action.payload,
            }

        case READING_LONG_ANSWER:
            return {
                ...state,
                readingLongAnswer: action.payload,
            }

        case READING_SHORT_ANSWER:
            return {
                ...state,
                readingShortAnswer: action.payload,
            }

        case READING_JUMBLE:
            return {
                ...state,
                readingJumble:  action.payload,
            }

        case LISTENING_MCQ:
            return {
                ...state,
                listeningMcq: action.payload,
            }

        case LISTENING_TRUEORFALSE:
            return {
                ...state,
                listeningTrueOrFalse: action.payload,
            }

        case LISTENING_LONG_ANSWER:
            return {
                ...state,
               listeningLongAnswer: action.payload,
            }

        case LISTENING_SHORT_ANSWER:
            return {
                ...state,
                listeningShortAnswer: action.payload,
            }

        case LISTENING_FILLBLANKS:
            return {
                ...state,
                listeningFillBlanks: action.payload,
            }

        case WRITING_DATA:
            return {
                ...state,
                writingData: action.payload,
            }

        case SPEAKING_DATA:
            return {
                ...state,
                speakingData: action.payload,
            }

        case LOGOUT:
            return {
                ...state,
                isLoggedIn: action.payload,
            };

        default:
            return state;
    }
};
