import React, {useEffect, useState} from 'react';
import classes from './Mcq.module.css';
const Mcq = (props) => {
    const [state, setState] = useState(null)
    // useEffect(()=>{
    //     console.log('Selected answer ===> ',state,' for',props.question.question);
    // },[state])
    const radioChangeHandler = (e) => {
        setState(e.target.value);
        props.add(props.id,e.target.value);
    };
    return(
        <React.Fragment>
            <div className={classes.outerContainer}>
                <div className={classes.img}>
                    {props.image && <img src={props.image} alt={props.id} className={classes.image}/>}
                    {props.audio && <audio controls src={props.audio}/>}
                </div>
                <p className={classes.question}>{props.question.question}</p>
                <div className={classes.innerContainer}>
                    {/*<div className={classes.group}>*/}
                    {/*    <input type="radio" value={props.question.option1} name={props.question.question} onChange={radioChangeHandler}/> <label htmlFor={props.question.option1}>{props.question.option1}</label>*/}
                    {/*</div>*/}
                    {/*<div className={classes.group}>*/}
                    {/*    <input type="radio" value={props.question.option2} name={props.question.question} onChange={radioChangeHandler}/> <label htmlFor={props.question.option2}>{props.question.option2}</label>*/}
                    {/*</div>*/}
                    {/*<div className={classes.group}>*/}
                    {/*    <input type="radio" value={props.question.option3} name={props.question.question} onChange={radioChangeHandler}/> <label htmlFor={props.question.option3}>{props.question.option3}</label>*/}
                    {/*</div>*/}
                    {/*<div className={classes.group}>*/}
                    {/*    <input type="radio" value={props.question.option4} name={props.question.question} onChange={radioChangeHandler}/> <label htmlFor={props.question.option4}>{props.question.option4}</label>*/}
                    {/*</div>*/}
                    {props.question.options && props.question.options.map((item) => <div className={classes.group}>
                        <input type="radio" value={item} name={props.question.question} onChange={radioChangeHandler}/> <label htmlFor={item}>{item}</label>
                    </div>)}
                    {props.option && props.option.map((item) => <div className={classes.group}>
                        <input type="radio" value={item} name={props.option} onChange={radioChangeHandler}/> <label htmlFor={item}>{item}</label>
                    </div>)}
                    {props.options && props.options.map((item)=> <div className={classes.group}>
                        <input type="radio" value={item} name={props.question.question} onChange={radioChangeHandler}/> <label htmlFor={item}>{item}</label>
                    </div>)}
                </div>
            </div>
        </React.Fragment>
    );
};
export default Mcq;
