import React, {useEffect, useState} from 'react';
import {Link,useNavigate} from 'react-router-dom';
import classes from './SelectExamType.module.css';
import StudentInfoCard from "../components/StudentInfoCard";
import ExamTypeCard from "../components/ExamTypeCard";
import {useSelector,useDispatch} from "react-redux";
import {
    getListeningLongAnswer,
    getListeningMcq,
    getListeningShortAnswer,
    getListeningTrueOrFalse,
    getReadingJumble,
    getReadingLongAnswer,
    getReadingFillBlanks,
    getListeningFillBlanks,
    getReadingMcq,
    getReadingShortAnswer,
    getReadingTrueOrFalse,
    getReadingUnseen,
    getSpeakingData,
    getUnseenLong,
    getUnseenMcq,
    getUnseenShort,
    getUnseenTrueOrFalse,
    getWritingData,
    selectExamType,
    selectLoa,
    getListening
} from "../stores/actions/auth";
import LoadingOverlay from 'react-loading-overlay';
import CustomModal from "../ui/CustomModal";
import {B2} from "./LevelAssessmentPage";
import studentInfoCard from "../components/StudentInfoCard";


const SelectExamType = () => {

    // useEffect(() => {
    //     document.body.requestFullscreen()
    //         .then()
    //         .catch(err => console.log(err));
    // },[]);

    useEffect(() => {
        const requestFullScreen = () => {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen().catch(err => console.log(err));
            } else if (document.documentElement.mozRequestFullScreen) { // Firefox
                document.documentElement.mozRequestFullScreen().catch(err => console.log(err));
            } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                document.documentElement.webkitRequestFullscreen().catch(err => console.log(err));
            } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
                document.documentElement.msRequestFullscreen().catch(err => console.log(err));
            }
        };

        // Check if fullscreen is supported and ask for permission
        if (document.fullscreenEnabled) {
            // You can customize this part to ask for user permission, e.g., show a modal
            // and call requestFullScreen if the user grants permission.
            requestFullScreen();
        } else {
            console.log("Fullscreen not supported");
        }
    }, []);

    const selectLoa = useSelector(state=>state.auth.loa);
    const submit = useSelector(state=>state.auth.submit);
    const userInfo = useSelector(state => state.auth.username_info);
    const [speakingLoading, setSpeakingLoading] = useState(false);
    const [writingLoading, setWritingLoading] = useState(false);
    const [listeningLoading, setListeningLoading] = useState(false);
    const [readingLoading, setReadingLoading] = useState(false);
    const [unseenLoading, setUnseenLoading] = useState(false);
    const [showModal, setShowModal]= useState(false);

    console.log(selectLoa)
    const dispatch = useDispatch();
    const navigate= useNavigate();
    // const loa= useSelector(state => state.auth.loa);
    const NavigationHandler = () => {
        navigate('../exam');
    }
    return(
        <React.Fragment>
        <div className={classes.outerContainer}>
            <StudentInfoCard studentInfo={userInfo}/>
            <div className={classes.typecardcontainer}>
                <div className={classes.typecard}>
                    {/*{examType.map((item) => <ExamTypeCard title={item.type} key={item.id}/>)}*/}
                        <div className={classes.gradient1} onClick={async()=>{
                            setSpeakingLoading(true);
                            dispatch(selectExamType(SPEAKING));
                            await dispatch(getSpeakingData(userInfo[0].loa));
                            // await dispatch(getSpeakingData(loa));
                            setSpeakingLoading(false);
                            NavigationHandler();
                        }}>
                            {speakingLoading && <div className={classes.loading}><LoadingOverlay active={speakingLoading} spinner/></div>}
                            {!speakingLoading && <ExamTypeCard title={SPEAKING} image={require('../assets/speaking.png')}/>}
                        </div>
                    <div className={classes.gradient1} onClick={async()=>{
                        setWritingLoading(true);
                        dispatch(selectExamType(WRITING));
                        await dispatch(getWritingData(userInfo[0].loa));
                        // await dispatch(getWritingData(loa));
                        setWritingLoading(false);
                        NavigationHandler();
                    }}>
                        {writingLoading && <div className={classes.loading}><LoadingOverlay active={writingLoading} spinner/></div>}
                        {!writingLoading && <ExamTypeCard title={WRITING} image={require('../assets/Writing.png')}/>}
                    </div>
                    <div className={classes.gradient1} onClick={async ()=>{
                        setListeningLoading(true);
                        dispatch(selectExamType(LISTENING));
                        await dispatch(getListening(userInfo[0].loa))
                        // await dispatch(getListening(loa))
                        // await dispatch(getListeningMcq(userInfo[0].loa))
                        // await dispatch(getListeningTrueOrFalse(userInfo[0].loa))
                        // await dispatch(getListeningLongAnswer(userInfo[0].loa))
                        // await dispatch(getListeningShortAnswer(userInfo[0].loa))
                        // await dispatch(getListeningFillBlanks(userInfo[0].loa))
                        setListeningLoading(false);
                        NavigationHandler();
                    }}>
                        {listeningLoading && <div className={classes.loading}><LoadingOverlay active={listeningLoading} spinner/></div>}
                        {!listeningLoading && <ExamTypeCard title={LISTENING} image={require('../assets/listening.png')}/>}
                    </div>
                    <div className={classes.gradient1} onClick={async()=>{
                        setReadingLoading(true);
                        dispatch(selectExamType(READING));
                        await dispatch(getReadingMcq(userInfo[0].loa));
                        await dispatch(getReadingTrueOrFalse(userInfo[0].loa));
                        await dispatch(getReadingLongAnswer(userInfo[0].loa));
                        await dispatch(getReadingShortAnswer(userInfo[0].loa));
                        await dispatch(getReadingJumble(userInfo[0].loa));
                        await dispatch(getReadingUnseen(userInfo[0].loa));
                        await dispatch(getReadingFillBlanks(userInfo[0].loa));
                        // await dispatch(getReadingMcq(loa));
                        // await dispatch(getReadingTrueOrFalse(loa));
                        // await dispatch(getReadingLongAnswer(loa));
                        // await dispatch(getReadingShortAnswer(loa));
                        // await dispatch(getReadingJumble(loa));
                        // await dispatch(getReadingUnseen(loa));
                        // await dispatch(getReadingFillBlanks(loa));
                        setReadingLoading(false);
                        NavigationHandler();
                    }}>
                        {readingLoading && <div className={classes.loading}><LoadingOverlay active={readingLoading} spinner/></div>}
                        {!readingLoading && <ExamTypeCard title={READING} image={require('../assets/Reading.png')}/>}
                    </div>
                    {/*<div className={classes.gradient1} onClick={async()=>{*/}
                    {/*    setUnseenLoading(true);*/}
                    {/*    await dispatch(selectExamType(UNSEEN_COMPREHENSION));*/}
                    {/*    await dispatch(getUnseenTrueOrFalse(selectLoa));*/}
                    {/*    await dispatch(getUnseenMcq(selectLoa));*/}
                    {/*    await dispatch(getUnseenLong(selectLoa));*/}
                    {/*    await dispatch((getUnseenShort(selectLoa)));*/}
                    {/*    setReadingLoading(false);*/}
                    {/*    NavigationHandler();*/}
                    {/*}}>*/}
                    {/*    {unseenLoading && <div className={classes.loading}><LoadingOverlay active={unseenLoading} spinner/></div>}*/}
                    {/*    {!unseenLoading && <ExamTypeCard title={UNSEEN_COMPREHENSION} image={require('../assets/information.png')}/>}*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
            {submit && <CustomModal onClose={()=>setShowModal(false)} open={showModal}>
                Exam Submitted Successfully!
            </CustomModal>}
        </React.Fragment>
    );
};
export default SelectExamType;
export const READING = 'Reading';
export const WRITING = 'Writing';
export const LISTENING = 'Listening';
export const SPEAKING = 'Speaking';
export const UNSEEN_COMPREHENSION = 'Unseen Comprehension';
