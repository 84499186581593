import React from 'react';
import Mcq from "./Mcq";
import TrueOrFalse from "./TrueOrFalse";
import LongAnswer from "./LongAnswer";
import ShortAnswer from "./ShortAnswer";
import classes from './Unseen.module.css';
const Unseen = (props) => {
    return(
        <React.Fragment>
            {/*Passage div*/}
            <div className={classes.outerContainer}>
                <div className={classes.title}>
                    <b>Listening</b>
                </div>
                {/*{console.log(props.data.audio[0].url)}*/}
                {<audio controls src={props.data.audio[0].url}/>}
            </div>

            {/*Questions div*/}
            {props.data.audioQuestions[0].map(item => {
                // console.log(item[0].id)
                switch (item.relationTable){
                    case 'Listenmcqs':
                        return <Mcq
                            question={item[0]}
                            id={item['0'].id }
                            key={item['0'].id }
                            add={props.addMcq}
                            option={item.options}
                        />
                    case 'Listentruefalses' :
                        return <TrueOrFalse
                            question={item[0]}
                            image={item[0].url}
                            id={item['0'].id}
                            key={item['0'].id}
                            add={props.addTof}
                        />
                    case 'Listenlongques':
                        return <LongAnswer
                            question={item[0].question}
                            image={item[0].url}
                            id={item['0'].id}
                            key={item['0'].id}
                            add={props.addLong}
                        />
                    case 'Listenshortques':
                        return <ShortAnswer
                            question={item[0]}
                            image={item[0].url}
                            id={item['0'].id}
                            key={item['0'].id}
                            add={props.addShort}
                        />
                    case 'Listenfillblanks':
                        return <Mcq
                            question={item[0]}
                            image={item[0].url}
                            id={item['0'].id }
                            key={item['0'].id }
                            add={props.addFill}
                            option={item.options}
                        />
                }
            })}
            {/*<div>*/}
            {/*    {props.data.passageQuestions.map(item => {*/}
            {/*        // console.log(item.relationTable);*/}
            {/*        switch (item.relationTable){*/}
            {/*            case 'Unseenreadmcqs':*/}
            {/*                return <Mcq*/}
            {/*                    question={item[0]}*/}
            {/*                    image={item[0].url}*/}
            {/*                    id={item[0].id }*/}
            {/*                    key={item[0].id }*/}
            {/*                    add={props.addMcq}*/}
            {/*                    option={item.options}*/}
            {/*                />*/}
            {/*            case 'Unseenreadjumbles':*/}
            {/*                return <Jumble*/}
            {/*                    data={item[0]}*/}
            {/*                    id={item[0].id}*/}
            {/*                    key={item[0].id}*/}
            {/*                    options={item.options}*/}
            {/*                    images={item.images}*/}
            {/*                    add={props.addJumble}*/}
            {/*                />*/}
            {/*            case 'Unseenreadfillblanks':*/}
            {/*                return <Mcq*/}
            {/*                    question={item[0]}*/}
            {/*                    image={item[0].url}*/}
            {/*                    id={item[0].id }*/}
            {/*                    key={item[0].id }*/}
            {/*                    add={props.addFill}*/}
            {/*                    option={item.options}*/}
            {/*                />*/}
            {/*            case 'Unseenreadlongques':*/}
            {/*                return <LongAnswer*/}
            {/*                    question={item[0].question}*/}
            {/*                    image={item[0].url}*/}
            {/*                    id={item[0].id}*/}
            {/*                    key={item[0].id}*/}
            {/*                    add={props.addLong}*/}
            {/*                />*/}
            {/*            case 'Unseenreadshortques':*/}
            {/*                return <ShortAnswer*/}
            {/*                    question={item[0]}*/}
            {/*                    image={item[0].url}*/}
            {/*                    id={item[0].id}*/}
            {/*                    key={item[0].id}*/}
            {/*                    add={props.addShort}*/}
            {/*                />*/}
            {/*            case 'Unseenreadtruefalses':*/}
            {/*                return <TrueOrFalse*/}
            {/*                    question={item[0]}*/}
            {/*                    image={item[0].url}*/}
            {/*                    id={item[0].id}*/}
            {/*                    key={item[0].id}*/}
            {/*                    add={props.addTof}*/}
            {/*                />*/}
            {/*        }*/}
            {/*    })}*/}
            {/*</div>*/}
        </React.Fragment>
    );
};

export default Unseen;
