import React, {useState} from 'react';
import classes from "./AddStudent.module.css"
import NavBar from "../components/NavBar";
import Select from "react-select";

const AddStudent = () => {
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [category, setCategory] = useState(null);
    const [yearStudied, setYearStudied] = useState(null);
    const [gender, setGender] = useState(null);

    const gradeOptions =[
        { value: 1, label: "1/2"},
        { value: 2, label: "2/3"},
        { value: 3, label: "3/4"},
        { value: 4, label: "4/5"},
        { value: 5, label: "5/6"},
        { value: 6, label: "6/7"},
        { value: 7, label: "7/8"},
        { value: 8, label: "8/9"},
        { value: 9, label: "9/10"},
    ];
    const yearStudiedOptions = [
        {value: 1, label: '(A1)1/2'},
        {value: 2, label: '(A2)3/4'},
        {value: 3, label: '(B1)5/6'},
        {value: 4, label: '(B2)7/8'},
        {value: 5, label: '(C)9/10'},
    ];
    const genderOptions = [
        {value: 1, label: 'Male'},
        {value: 2, label: 'Female'},
        {value: 3, label: 'Trans'},
    ];
    const categoryOptions = [
        {value: 1, label: 'General'},
        {value: 2, label: 'SEN'},
    ];

    const handleGradeSelection = (selectedOption) => {
        setSelectedGrade(selectedOption);
        console.log(`Grade selected:`, selectedOption);
    };

    const handleYearStudiedSelection = (selectedOption) => {
        setYearStudied(selectedOption);
        console.log(`YearStudied selected:`, selectedOption);
    };

    const handleGenderSelection = (selectedOption) => {
        setGender(selectedOption);
        console.log(`Gender selected:`, selectedOption);
    };

    const handleCategorySelection = (selectedOption) => {
        setCategory(selectedOption);
        console.log(`Category selected:`, selectedOption);
    };

    return (
        <React.Fragment>
            <div className={classes.outerContainer}>
            <form className={classes.formContainer}>
                <div className={classes.heading}>Add Student</div>
                <div className={classes.fieldsContainer}>
                <div className={classes.inputContainers}>
                <label htmlFor="schlName" className={classes.labels}>School Name: </label>
                <input value="ABCDEFGHIJLKLMNOP" readOnly={true} type="text" name="School Name" className={classes.inputs} />
            </div>
                <div className={classes.inputContainers}>
                <label htmlFor="schlRegNum" className={classes.labels}>School Registration Number: </label>
                <input value="ZPUPS001402" readOnly={true} type="text" name="School Registration Number" className={classes.inputs} />
                </div>
                <div className={classes.inputContainers}>
                    <label htmlFor="stdName" className={classes.labels}>Student Full Name: </label>
                <input type="text" name="Student Full Name" className={classes.inputs} />
                </div>
                <div className={classes.inputContainers}>
                    <label htmlFor="stdID" className={classes.labels}>Student ID: </label>
                <input type="text" name="Student ID" className={classes.inputs} />
                </div>
                <div className={classes.inputContainers}>
                    <label htmlFor="nationality" className={classes.labels}>Nationality: </label>
                <input type="text" name="Nationality" className={classes.inputs} />
                </div>
                    <div className={classes.inputContainers}>
                        <label htmlFor="grade" className={classes.labels}>Grade/Year: </label>
                        <Select className={classes.dropdownInputs} options={gradeOptions} onChange={handleGradeSelection} autoFocus={true} />
                    </div>
                <div className={classes.inputContainers}>
                    <label htmlFor="dob" className={classes.labels}>Date Of Birth: </label>
                <input type="date" name="Date Of Birth" className={classes.inputs} />
                </div>
                <div className={classes.inputContainers}>
                    <label htmlFor="gender" className={classes.labels}>Gender: </label>
                    <Select className={classes.dropdownInputs} options={genderOptions} onChange={handleGenderSelection} autoFocus={true} />
                </div>
                <div className={classes.inputContainers}>
                    <label htmlFor="yrStudied" className={classes.labels}>How many years studied language?: </label>
                    <Select className={classes.dropdownInputs} options={yearStudiedOptions} onChange={handleYearStudiedSelection} autoFocus={true} />
                </div>
                <div className={classes.inputContainers}>
                    <label htmlFor="loa" className={classes.labels}>Level of Assessment: </label>
                <input type="text" name="LOA" className={classes.inputs} />
                </div>
                <div className={classes.inputContainers}>
                    <label htmlFor="category" className={classes.labels}>General/SEN: </label>
                    <Select className={classes.dropdownInputs} options={categoryOptions} onChange={handleCategorySelection} autoFocus={true} />
                </div>
                    <div className={classes.buttonContainer}>
                        <div className={classes.button}>Add Student</div>
                    </div>
                </div>
            </form>
            </div>
        </React.Fragment>
    );
};

export default AddStudent;
