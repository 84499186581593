import React from 'react';
import {useNavigate} from "react-router-dom";
import classes from "./SchoolLogin.module.css";

const Login = () => {
    const navigate= useNavigate();
    const navigationHandler =()=>{
        navigate('/school/addStd');
    }
    return(
        <React.Fragment>
            <div className={classes.outercontainer}>
                <div className={classes.innercontainer}>
                    {/*<div className={classes.page}>*/}
                    {/*<div className={classes.container}>*/}
                    {/*    <img className={classes.image} src={require('../src/assets/vecteezy_illustration-vector-graphic-cartoon-character-of-home-study_6584582.jpg')} alt={""}/>*/}
                    {/*</div>*/}
                    <div className={classes.form}>
                        <div className={classes.title}>School Login</div>
                        <form>
                            <div className={classes.username}>
                                <label>
                                    {/*<div className={classes.labelContainer}>Username</div>*/}
                                    <input type="text" name="username" placeholder={"Username"} size={30}/>
                                </label>
                            </div>
                            <div className={classes.password}>
                                <label>
                                    {/*<div className={classes.labelContainer}>Password</div>*/}
                                    <input type="text" name="password" placeholder={"Password"} size={30}/>
                                </label>
                            </div>
                            {/*<div className={classes.password}>*/}
                            {/*    <label>*/}
                            {/*        <div className={classes.labelContainer}>Confirm Password</div>*/}
                            {/*        <input type="text" name="confirm" size={70}/>*/}
                            {/*    </label>*/}
                            {/*</div>*/}
                            <div className={classes.button}>
                                <button type="submit" onClick={navigationHandler}>Log in</button>
                            </div>
                        </form>
                    </div>
                    {/*</div>*/}
                    <div className={classes.icon}>
                        <img className={classes.image} src={require('../assets/school.jpeg.jpg')} alt={""}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Login;
