
import React from 'react';
import {fetchGET, fetchPOST} from '../../utils/NetworkUtils';
import {
    AUTHENTICATE_URL,
    READING_URL,
    WRITING_URL,
    LISTENING_URL,
    SPEAKING_URL,
    SUBMIT_URL
} from "../../constants/URLConstants";
import {KEY_USER_TOKEN, KEY_PASSWORD, KEY_LOGIN_DATA, KEY_LOGIN_URL, KEY_USERNAME} from "../../constants/AppDefines";



export const AUTHENTICATE = 'AUTHENTICATE';
export const USERNAME_INFO = 'USERNAME_INFO';
export const SET_DID_TRY_AUTO_LOGIN = 'SET_DID_TRY_AUTO_LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOA_TYPE = 'LOA_TYPE';
export const EXAM_TYPE = 'EXAM_TYPE';
export const READING_MCQ = 'READING_MCQ';
export const READING_TRUEORFALSE = 'READING_TRUEORFALSE';
export const READING_LONG_ANSWER = 'READING_LONG_ANSWER';
export const READING_SHORT_ANSWER = 'READING_SHORT_ANSWER';
export const READING_UNSEEN = 'READING_UNSEEN';
export const READING_JUMBLE = 'READING_JUMBLE';
export const READING_FILLBLANKS = 'READING_FILLBLANKS';
export const WRITING_DATA = 'WRITING_DATA';
export const LISTENING_MCQ = 'LISTENING_MCQ';
export const LISTENING_FILLBLANKS = 'LISTENING_FILLBLANKS';
export const LISTENING_TRUEORFALSE = 'LISTENINGTRUEORFALSE';
export const LISTENING_LONG_ANSWER = 'LISTENING_LONG_ANSWER';
export const LISTENING_SHORT_ANSWER = 'LISTENING_SHORT_ANSWER';
export const SPEAKING_DATA = 'SPEAKING_DATA';
export const UNSEEN_LONG_ANSWER = 'UNSEEN_LONG_ANSWER';
export const UNSEEN_SHORT_ANSWER = 'UNSEEN_SHORT_ANSWER';
export const UNSEEN_MCQ = 'UNSEEN_MCQ';
export const UNSEEN_TRUEORFALSE = 'UNSEEN_TRUEORFALSE';
export const SUBMIT = 'SUBMIT';
export const TIMER = 'TIMER';
export const LISTENING = 'LISTENING';
export const getUnseenMcq = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'UnseenlistenmcqsByLoa/' + type);
            console.log(resData);
            dispatch({
                type:UNSEEN_MCQ,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getUnseenLong = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'UnseenlistenlongquesByLoa/' + type);
            console.log(resData);
            dispatch({
                type:UNSEEN_LONG_ANSWER,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getUnseenShort = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'UnseenlistenshortquesByLoa/' + type);
            console.log(resData);
            dispatch({
                type:UNSEEN_SHORT_ANSWER,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getUnseenTrueOrFalse = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'UnseenlistentruefalsesByLoa/' + type);
            console.log(resData);
            dispatch({
                type:UNSEEN_TRUEORFALSE,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const timerExpiredTrue = () => {
    return async dispatch => {
        try {
            dispatch({
                type: TIMER,
                payload: true,
            });
        }catch(error){
            console.log(error)
            throw error
        }
    }
}
export const timerExpiredFalse = () => {
    return async dispatch => {
        try {
            dispatch({
                type: TIMER,
                payload: false,
            });
        }catch(error){
            console.log(error)
            throw error
        }
    }
}
export const authenticate = (username, password) => {
    return async dispatch => {

        try {
            console.log("trying login");
            let bearer = localStorage.getItem(KEY_USER_TOKEN);
            let token = 'Bearer ' + bearer;
            const postData = {
                username: username,
                password: password
            }
            let response = await fetch(AUTHENTICATE_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify(postData),
            });

            if (!response.ok) {
                if (response.status === 500) {
                    throw new Error('Internal Server Error');
                }

                if (response.status === 401) {
                    throw new Error('Unauthorized Access');
                }
            }

            const resData = await response.json();

            console.log('RESPONSE DATA ===> ' + JSON.stringify(resData));
            await localStorage.setItem(KEY_USER_TOKEN,resData.data.token);
            if (resData.data.error_code !== undefined) {
                throw new Error(resData.data.error_message);
            }
            dispatch({
                type: AUTHENTICATE,
                payload: resData.data.token,
            });
            dispatch({
               type: USERNAME_INFO,
               payload: resData.data.data,
            });
            dispatch({
                type: LOGOUT,
                payload: true,
            });
            console.log("login done");
        } catch (error) {
            console.log(error);
            throw error;
        }
    };
};

export const submit = (data) => {
    return async dispatch => {
        try{
            const response = await fetchPOST(SUBMIT_URL,data);
            console.log('response==>', response);
            dispatch({
                type: SUBMIT,
                payload: response,
            })
        } catch(err){
            console.log(err);
            // throw err;
        }
    }
}
export const emptySubmit = () => {
    return async dispatch => {
        try{
            dispatch({
                type: SUBMIT,
                payload: null,
            })
        }catch(err){
            console.log(err);
            throw err;
        }
    }
}
export const logoutUser = () => {
    return async dispatch => {
        //we need to reset all keys

        try {
            await localStorage.removeItem(KEY_USER_TOKEN);
            await localStorage.removeItem(KEY_USERNAME);
            await localStorage.removeItem(KEY_PASSWORD);
            await localStorage.removeItem(KEY_LOGIN_URL);
            await localStorage.removeItem(KEY_LOGIN_DATA);

            // GoogleSignInModule.signOut();
            dispatch({type: LOGOUT, payload: false});
        } catch (error) {
            console.log(error);
            throw error;
        }
    };
};

export const selectLoa = (type) => {
    return async dispatch => {
        try{
            // console.log("LOAtype===>",type);
            dispatch({
                type:LOA_TYPE,
                payload: type,
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const selectExamType = (type) => {
    return async dispatch => {
        try{
            dispatch({
                type:EXAM_TYPE,
                payload: type,
            });
        }
        catch(error) {
            console.log(error);
            throw error;
        }
    };
};
//going to write a new code for authentication flow
export const getReadingMcq = (type) => {

    return async dispatch => {
        try{
            const resData = await fetchGET(READING_URL + "ReadmcqsByLoa/" + type);
            console.log(resData);
            dispatch({
                type:READING_MCQ,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getReadingTrueOrFalse = (type) => {

    return async dispatch => {
        try{
            const resData = await fetchGET(READING_URL + "ReadtruefalsesByLoa/" + type);
            console.log(resData);
            dispatch({
                type:READING_TRUEORFALSE,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getReadingLongAnswer = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(READING_URL + "ReadlongquesByLoa/" + type);
            console.log(resData);
            dispatch({
                type:READING_LONG_ANSWER,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getReadingShortAnswer = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(READING_URL + "ReadshortquesByLoa/" + type);
            console.log(resData);
            dispatch({
                type:READING_SHORT_ANSWER,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getReadingJumble = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(READING_URL + "ReadjumblesByLoa/" + type);
            console.log(resData);
            dispatch({
                type:READING_JUMBLE,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getReadingFillBlanks = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(READING_URL + "ReadfillblanksByLoa/" + type);
            console.log(resData);
            dispatch({
                type:READING_FILLBLANKS,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getReadingUnseen = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(READING_URL + "UnseenReading/" + type);
            console.log(resData.data);
            dispatch({
                type:READING_UNSEEN,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getWritingData = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(WRITING_URL + type);
            console.log(resData);
            dispatch({
                type:WRITING_DATA,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getListening = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL + "UnseenReading/" + type);
            console.log(resData.data);
            dispatch({
                type:LISTENING,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getListeningMcq = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'ListenmcqsByLoa/' + type);
            console.log(resData);
            dispatch({
                type:LISTENING_MCQ,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getListeningFillBlanks = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'ListenfillblanksByLoa/' + type);
            console.log(resData);
            dispatch({
                type:LISTENING_FILLBLANKS,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getListeningTrueOrFalse = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'ListentruefalsesByLoa/' + type);
            console.log(resData);
            dispatch({
                type:LISTENING_TRUEORFALSE,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getListeningLongAnswer = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'ListenlongquesByLoa/' + type);
            console.log(resData);
            dispatch({
                type:LISTENING_LONG_ANSWER,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getListeningShortAnswer = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(LISTENING_URL+ 'ListenshortquesByLoa/' + type);
            console.log(resData);
            dispatch({
                type:LISTENING_SHORT_ANSWER,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
export const getSpeakingData = (type) => {
    return async dispatch => {
        try{
            const resData = await fetchGET(SPEAKING_URL + type);
            console.log(resData);
            dispatch({
                type:SPEAKING_DATA,
                payload: resData.data
            });
        }catch(error){
            console.log(error);
            throw error;
        }
    }
};
