import React from 'react';
import classes from './SpeakingSkills.module.css';
import {AudioRecorder, useAudioRecorder} from "react-audio-voice-recorder";
import {useState} from "react";

const SpeakingSkills = (props) => {
    const [address, setAddress] = useState('');
    const deleteHandler=()=>{
        setAddress('');
    }
    const addAudioElement = async (blob: Blob) => {
        const url = URL.createObjectURL(blob);
        const audio = document.createElement('audio');
        audio.src = url;
        audio.controls = true;
        setAddress(url);
        // const formData = await blobToBase64(blob);
        props.add(props.id, address)

        // document.body.appendChild(audio);
    };
    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
    return (
        <React.Fragment>
            <div className={classes.outerContainer}>
                <div className={classes.image}>
                    {props.image && <img src={props.image} alt={props.id} className={classes.url}/>}
                </div>
                <div className={classes.text}>
                    <p className={classes.question}>{props.question}</p>
                    <div className={classes.innerContainer}>
                        <div className={classes.audio}>
                        <AudioRecorder
                            onRecordingComplete={addAudioElement}
                            audioTrackConstraints={{
                                noiseSuppression: true,
                                echoCancellation: true,
                            }}
                            onNotAllowedOrFound={(err) => console.table(err)}
                            mediaRecorderOptions={{
                                audioBitsPerSecond: 128000,
                            }}
                            showVisualizer={true}
                        />
                        </div>
                        <div className={classes.delete}>
                            {address && <audio controls src={address}/>}
                            {address && <input type="button" value="Delete" onClick={deleteHandler}/>}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SpeakingSkills;
