import classes from "./Bullet.module.css";
import React from "react";

const Bullet = (props) => {
    return(
        <React.Fragment>
            <div className={classes.bullet}/>{props.children}
        </React.Fragment>
    );
};

export default Bullet;