import React from 'react';
import classes from './ExamTypeCard.module.css';
import {HiOutlineSpeakerWave} from "react-icons/hi2";
const ExamTypeCard = (props) => {
    return(
        <div className={classes.container}>
            <div className={classes.circle}>
                <img src={props.image} alt={props.title} className={classes.image}/>
            </div>
            <div className={classes.title}>
                {props.title}
            </div>
            <div className={classes.status}>
                Incomplete
            </div>
        </div>
    );
};
export default ExamTypeCard;
