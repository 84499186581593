import React from 'react';
import classes from './CourseCard.module.css';
const CourseCard = (props) => {
    return(
        <React.Fragment>
            <div className={classes.OuterContainer}>
                <div className={classes.InnerContainer}>
                    <div className={classes.Header}>
                        <div className={classes.Title}>
                            Level of Assessment
                            <p>{props.loa}</p>
                        </div>
                    </div>
                    <div className={classes.Body}>
                        <div className={classes.Info}>
                            {props.text}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CourseCard;
