import React from 'react';
import {Link,useNavigate} from 'react-router-dom';
import classes from './LevelAssessmentPage.module.css';
import CourseCard from "../components/CourseCard";
import {useDispatch} from "react-redux";
import {selectLoa} from "../stores/actions/auth";
const LevelAssessmentPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const navigationHandler = () => {
        navigate('../examtype');
    }
    return (
        <div>
            <div className={classes.Uppercontainer}>
                <div onClick={()=>{
                    dispatch(selectLoa(A1));
                    navigationHandler();
                }}><CourseCard loa={A1} text={"At the A1 level, learners have very basic language skills. "}/></div>
                <div onClick={()=>{
                    dispatch(selectLoa(A2));
                    navigationHandler();
                }}><CourseCard loa={A2} text={"At the A2 level, learners can handle familiar topics with more ease."}/></div>
                <div onClick={()=>{
                    dispatch(selectLoa(B1));
                    navigationHandler();
                }}><CourseCard loa={B1} text={"B1-level learners can understand and communicate in a wider range of situations."}/></div>
                <div onClick={()=>{
                    dispatch(selectLoa(B2));
                    navigationHandler();
                }}><CourseCard loa={B2} text={"B2-level learners have a good grasp of the language."}/></div>
                <div onClick={()=>{
                    dispatch(selectLoa(C1));
                    navigationHandler();
                }}><CourseCard loa={C1} text={"C1-level learners have a high level of language proficiency."}/></div>
            </div>
        </div>
    );
};

export default LevelAssessmentPage;
export const A1 = "A1";
export const A2 = "A2";
export const B1 = "B1";
export const B2 = "B1";
export const C1 = "C1";
