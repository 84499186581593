import React from 'react';
import classes from './OptionContainer.module.css';
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from '@dnd-kit/utilities';

const OptionContainer = (props) => {
    const {attributes,
        listeners,
        setNodeRef,
        transform,
        transition} = useSortable({id: props.id});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    }
    return(
        <React.Fragment>
            <div className={classes.container} ref={setNodeRef} style={style} {...attributes} {...listeners}>
                <p className={classes.option}>{props.option}</p>
            </div>
        </React.Fragment>
    );
}
export default OptionContainer;
