import React from 'react';
import {Outlet} from "react-router-dom";
import NavBarSchool from "../components/NavBarSchool";

const SchoolLayout = () => {
    return (
        <React.Fragment>
            <NavBarSchool/>
            <Outlet/>
        </React.Fragment>
    )
};

export default SchoolLayout;
